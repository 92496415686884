html {
  height: 100%;
}

body {
  height: 100%;	
  width: 100%;
  margin: 0;
  background-color: #f5f5f5;
}

.vc-logo-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vc-logo-row > img {
  max-height: 50px;
  max-width: 438px;
}

.vc-basiq-logo-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-width: 100%;
  margin-top: 15px;
  margin-bottom: 5px;
}

.vc-basiq-logo-row > div {
  font-size: 14px;
  margin-top: auto;
  margin-bottom: auto;
  color: grey;
}

.vc-basiq-logo-row > a {
  height: 23px;
  margin-left: 5px;
  margin-right: 5px;
}

.vc-basiq-logo-row img {
  height: 100%;
}

@media (max-height: 600px), (max-width: 439px) {
  .vc-logo-row {
    max-width: 100%;
  }
  .vc-logo-row > img {
    max-width: 100%;
  }
}

@supports not (-ms-high-contrast: none) {
  #root {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  
  .vc-inner-container {
    display: grid;
    grid-template-areas: 
      "logo-row"
      "basiq-connect-container"
      "basiq-logo-row";
  }
  
  @media (min-height: 600px) and (min-width: 440px) {
    .vc-inner-container {
      width: 100%;
      height: 100%;
      justify-content: center;
      align-content: center;	
      grid-template-columns: 440px;
      grid-template-rows: 55px minmax(480px, auto) 45px;
    }
  }
  
  @media (max-height: 600px), (max-width: 439px) {
    .vc-inner-container {
      width: 100%;
      min-height: 100%;
      grid-template-columns: 100%;
      grid-template-rows: 55px auto 45px;
    }
  }

  .vc-logo-row {
    grid-area: logo-row;
  }

  #basiq-connect-container {
    grid-area: basiq-connect-container;
  }

  .vc-basiq-logo-row {
    grid-area: basiq-logo-row;
  }

  .vc-powered-by {
    font-family: sans-serif;
  }
}

@media all and (min-height: 601px) and (min-width: 440px) and (-ms-high-contrast: none), 
       all and (min-height: 601px) and (min-width: 440px) and (-ms-high-contrast: active) {
  #root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .vc-inner-container {
    display: flex;
    flex-direction: column;
  }
  
  #basiq-connect-container {
    display: flex;
    min-height: 480px;
    width: 440px;
  }
  
  .vc-powered-by {
    font-family: sans-serif;
  }
}

@media all and (max-height: 600px) and (-ms-high-contrast: none),
       all and (max-height: 600px) and (-ms-high-contrast: none),
       all and (max-width: 439px)  and (-ms-high-contrast: active),
       all and (max-width: 439px)  and (-ms-high-contrast: active) {
  #root {
    position: fixed;
    width: 100%;
    overflow-y: auto;
    height: 100%;
  }
  
  .vc-inner-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  #basiq-connect-container {
    display: flex;
    flex-grow: 1;
  }
  
  .vc-powered-by {
    font-family: sans-serif;
  }
}
